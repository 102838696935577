const Menu = [
  { header: "Ventas2" },
  {
    title: "Clientes",
    group: "listClients",
    name: "listClients",
    icon: "mdi-menu-right",
  },
  {
    title: "Productos",
    group: "productoos",
    name: "productoos",
    icon: "mdi-menu-right",
  },
  /*{
    title: "Productos",
    icon: "mdi-menu-right",
    group: "productosc",
    name: "facturacion",
    items: [
      {
        title: "Detalle",
        group: "productosc",
        component: "detailp",
      },
    ],
  },*/
];

export default Menu;
